import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

import AOS from "aos";

import Header from "../common/Header";
import Footer from "../common/Footer";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="homeContainer">
      <Header />

      <div className="contact">
        <Container maxWidth="lg">
          <div className="imgCover" />
        </Container>

        <div className="coverContainer">
          <div
            className="row justify-content-center align-items-center"
            style={{ height: "85vh" }}
          >
            <div className="col-lg-8">
              <div className="message pt-0" data-aos="fade-down">
                <div className="messageDetail">
                  {/* <div class="container-fluid no-padding">
                    <div class="row">
                      <div class="col-md-12">
                        <img
                          className="w-100"
                          src="/images/housepicture.jpg"
                          class="img-responsive"
                          alt="placeholder"
                        />
                      </div>
                    </div>
                  </div> */}

                  <h1>
                    <strong>Jenny Heng</strong>
                  </h1>
                  <p>
                    <strong>Email:</strong>{" "}
                    <a href="mailto:todaypropertiesllc@gmail.com">
                      todaypropertiesllc@gmail.com
                    </a>
                  </p>
                  <p>
                    <strong>Phone:</strong>{" "}
                    <a href="tel:5103753562">(510) 375-3562</a>
                  </p>
                  <div className="rated mt-5">
                    <i className="fas fa-star fa-2x"></i>
                    <i className="fas fa-star fa-2x"></i>
                    <i className="fas fa-star fa-2x"></i>
                    <i className="fas fa-star fa-2x"></i>
                    <i className="fas fa-star fa-2x"></i>
                  </div>
                </div>
              </div>
              <div className="calltoAction text-center mt-3">
                <Button variant="contained" color="primary">
                  <a
                    href="tel:5103753562"
                    style={{ listStyle: "none", color: "#fff" }}
                  >
                    Get in Contact
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Container maxWidth="lg">
          <div className="infoContainer mb-5">
            <h1 className="text-center py-3">
              <strong>What do we do?</strong>
            </h1>

            <div className="row shadow-lg rounded" data-aos="fade-down">
              <div className="col-lg-6">
                <img
                  src="/images/buyhouse.jpg"
                  className="w-100"
                  alt="Buying House"
                />
                <i className="fas fa-money-bill fa-4x"></i>
                <h2>We Buy Houses</h2>
                <p>
                  We let you skip the stress of selling your house the usual
                  way. Our whole process is simple, we begin an assessment of
                  the property then provide an offer to buy it.
                </p>
              </div>
              <div className="col-lg-6">
                <img
                  src="/images/sellhouse.jpg"
                  className="w-100"
                  alt="Selling House"
                />
                <i className="fas fa-house-user fa-4x"></i>
                <h2>We Sell Houses</h2>
                <p>
                  Any properties that we have available to sell on the market we
                  will list on our website. If you have any questions in regards
                  to the property we're selling feel free to give us a call!
                </p>
              </div>
              <div className="col-lg-6">
                <img
                  src="/images/renthouse.jpg"
                  className="w-100"
                  alt="Renting House"
                />
                <i className="fas fa-search-location fa-4x"></i>
                <h2>Renting Houses</h2>
                <p>
                  Any properties that are available to rent to a tenant we will
                  do and will go over the process as easy as possible, whether
                  it's your first time renting or you have previous history of
                  renting.
                </p>
              </div>
              <div className="col-lg-6">
                <img
                  src="/images/commercialbuilding.jpg"
                  className="w-100"
                  alt="Commercial Building"
                />
                <i className="fas fa-building fa-4x"></i>
                <h2>Commercial Buildings</h2>
                <p>
                  For commercial buildings around the area, we are also open to
                  the possibility of renting as well. Simply give us a call and
                  we can begin the process of understanding the layout, area and
                  demographics of the property building.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
