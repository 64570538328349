import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import { Link } from "react-router-dom";

import { Navbar } from "react-bootstrap";

const Header = () => {
  return (
    <div className="header">
      <CssBaseline />
      <Container maxWidth={false}>
        <>
          <Navbar bg="light">
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                width="70"
                height="70"
                className="d-inline-block align-top"
                alt="Today Properties Logo"
              />{" "}
              <h1>
                <strong>Today Properties LLC</strong>
              </h1>
            </Link>
          </Navbar>
        </>
      </Container>
    </div>
  );
};

export default Header;
