import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

const Footer = () => {
  var today = new Date();
  return (
    <>
      <footer className="text-center">
        <p className="font-weight-bold">
          Copyright &copy; {today.getFullYear()} Today Properties LLC | All
          Rights Reserved.
        </p>
      </footer>
    </>
  );
};

export default Footer;
